<template>
  <div class="catalog__inner">
    <div class="table catalog__table">
      <div class="table__head">
        <div class="table__th table__th--first">
          Наименование / Штрих-код
        </div>
        <div class="table__th table__th--second">
          Цена
        </div>
        <div class="table__th table__th--fourth" />
      </div>
      <template v-if="products?.length">
        <product-list-item v-for="product in products" :key="product.id" :product="product" />
      </template>
      <template v-else>
        <p class="not-found-message">
          К вашей компании не были добавлены товары, пожалуйста напишите нам на {{supportEmail}}, если это ошибка
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import ProductListItem from './ProductListItem.vue'
import {useApi} from "@/api";
import {ref} from "vue";

export default {
  components: {
    ProductListItem
  },
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  setup() {
    const api = useApi()
    const supportEmail = ref('')
    api.contacts.fetchContacts().then(({ data }) => {
      supportEmail.value = data.support_email
    })

    return {
      supportEmail
    }
  }
}
</script>

<template>
  <section class="catalog-section">
    <div class="page-header">
      <div class="container">
        <div class="page-header__inner">
          <div class="page-header__left">
            <h1>Каталог</h1>
            <div class="page-header__quantity">
              {{ pagination.totalCount }}
            </div>
          </div>
          <div class="search-block page-header__right">
            <div class="search-block__form">
              <label class="search-block__label">
                <span class="visually-hidden">
                  Поиск
                </span>
                <input
                  v-model="searchQuery"
                  class="search-block__input"
                  type="search"
                  placeholder="Поиск"
                  @keydown.enter="updateRouter"
                  @input="onSearch"
                  @focus="showSearch = true"
                  @blur="showSearch = false"
                >
              </label>
              <button v-if="searchQuery" class="search-block__icon" @click="setSearchQuery(null)">
                <span class="visually-hidden">Искать</span>
                <svg class="s-icon">
                  <use xlink:href="/img/svg/sprite1.svg#cancel" />
                </svg>
              </button>
              <button v-else class="search-block__icon" @click="updateRouter">
                <span class="visually-hidden">Искать</span>
                <svg class="s-icon s-icon--largest">
                  <use xlink:href="/img/svg/sprite1.svg#search" />
                </svg>
              </button>
            </div>
            <div v-if="showSearch && foundProducts.length" class="popup-search active">
              <ul class="popup-search__list">                
                <li
                  v-for="product in foundProducts"
                  :key="product.id"
                  class="popup-search__line"
                  @mousedown="setSearchQuery(product.name)"
                >
                  <a class="popup-search__link">
                    <word-highlighter
                      highlight-tag="span"
                      :query="searchQuery"
                      wrapper-tag="div"
                      wrapper-class="popup-search__title"
                    >
                      {{ product.name }}
                    </word-highlighter>
                    <word-highlighter highlight-tag="span" :query="searchQuery" wrapper-class="popup-search__code">
                      {{ Array.isArray(product.barcodes) ? product.barcodes.join(', ') : null }}
                    </word-highlighter>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <filters
      v-model:categories="filters.category_id"
      v-model:isBestseller="filters.is_bestseller"
      v-model:hasPromotion="filters.has_promotion"
      :hasItems="products.length > 0"
      @update:categories="updateRouter"
      @update:is-bestseller="updateRouter"
      @update:has-promotion="updateRouter"
    />
    <div class="preloader-container" :class="{ 'preloader-container--loading': !showPlaceholders && isLoading }">
      <transition name="fade">
        <preloader v-if="!showPlaceholders && isLoading" />
      </transition>
      <div ref="catalogContainer" class="catalog">
        <div class="container">
          <product-list-placeholder v-if="showPlaceholders" />
          <product-list v-else :products="products" />
        </div>
      </div>
      <paginate
        v-if="pagination.pageCount > 1"
        v-model="pagination.currentPage"
        :page-count="pagination.pageCount"
        :scrollable-element="catalogContainer"
        @scroll-paginate="onScrollPaginate"
        @paginate="paginate"
      />
    </div>
    <div v-if="cart" class="pagination__more-wrap active">
      <router-link to="/cart" class="pagination__more btn btn--primary btn--large">
        <span class="pagination__more-icon">
          <svg class="s-icon">
            <use xlink:href="/img/svg/sprite1.svg#cart" />
          </svg>
          <span class="pagination__more-quantity">{{ cartItemsCount }}</span>
        </span>
        {{ cartTotalPrice }}
      </router-link>
    </div>
  </section>
</template>

<script>
import Filters from '@/components/catalog/Filters.vue'
import ProductList from '@/components/catalog/ProductList.vue'
import ProductListPlaceholder from '@/components/placeholders/ProductListPlaceholder.vue'
import Preloader from '@/components/Preloader.vue'
import Paginate from '@/components/Paginate'
import debounce from 'lodash/debounce'
import parsePaginationHeaders from '@/common/parsePaginationHeaders'
import useCartState from '@/composables/useCartState'
import WordHighlighter from 'vue-word-highlighter'
import { FETCH_PRODUCTS, SEARCH_PRODUCTS } from '@/store/modules/products/actionTypes'
import { SET_FOUND_PRODUCTS } from '@/store/modules/products/mutationTypes'
import { computed, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import { useStore } from 'vuex'

const showPlaceholders = ref(true)
const filters = reactive({
  has_promotion: null,
  is_bestseller: null,
  category_id: []
})
const searchQuery = ref(null)
const pagination = ref({})
const searchParams = computed(() => {
  return {
    ...filters,
    query: searchQuery.value,
    page: pagination.value.currentPage
  }
})
const fetchProducts = (store, toast, append) => {
  store.dispatch(FETCH_PRODUCTS, { append, params: searchParams.value }).then(({ headers }) => {
    pagination.value = parsePaginationHeaders(headers)
  }).catch(() => {
    toast.error('Не удалось загрузить список товаров')
  }).finally(() => {
    showPlaceholders.value = false
  })
}
const parseFiltersFromRoute = (route) => {
  let { page, category_id, has_promotion, is_bestseller, query } = route.query
  category_id = Array.isArray(category_id) ? category_id : (typeof category_id === 'string' ? [category_id] : [])
  has_promotion = has_promotion === 'true' ? true : null
  is_bestseller = is_bestseller === 'true' ? true : null
  page = parseInt(page, 10) || 1
  pagination.value.currentPage = page
  filters.category_id = category_id
  filters.has_promotion = has_promotion
  filters.is_bestseller= is_bestseller
  searchQuery.value = query
}

export default {
  components: {
    Paginate,
    ProductList,
    ProductListPlaceholder,
    Filters,
    WordHighlighter,
    Preloader
  },
  beforeRouteEnter() {
    showPlaceholders.value = true
  },
  beforeRouteUpdate(to) {
    parseFiltersFromRoute(to)
    fetchProducts(this.$store, this.$toast)
  },
  setup() {
    const store = useStore()
    const toast = useToast()
    const route = useRoute()
    const router = useRouter()

    const catalogContainer = ref(null)
    const showSearch = ref(false)

    const isLoading = computed(() => store.getters.isProductsLoading)
    const products = computed(() =>  store.getters.getProducts)
    const foundProducts = computed(() => store.getters.getFoundProducts)
    

    const updateRouter = () => {
      debounceSearch.cancel()
      showSearch.value = false
      router.push({ path: '/', query: searchParams.value })
    }
    // const debouncedUpdateRouter = debounce(updateRouter, process.env.VUE_APP_DEBOUNCE_TIME)
    const onScrollPaginate = () => {
      const { currentPage, pageCount } = pagination.value
      if (!showPlaceholders.value && !isLoading.value && currentPage < pageCount) {
        pagination.value.currentPage++
        fetchProducts(store, toast, true)
      }
    }
    const paginate = () => {
      updateRouter()
    }

    parseFiltersFromRoute(route)
    fetchProducts(store, toast)
    const { cart, cartTotalPrice, cartItemsCount } = useCartState()

    const searchProducts = () => {
      if (searchQuery.value?.length >= 2) {
        store.dispatch(SEARCH_PRODUCTS, { query: searchQuery.value })
          .catch(() => {
            toast.error('Произошла ошибка')
          })
      } else {
        clearFoundProducts()
      }
    }
    const debounceSearch = debounce(searchProducts, process.env.VUE_APP_DEBOUNCE_TIME)
    const onSearch = () => {
      showSearch.value = true
      debounceSearch()
    }
    const clearFoundProducts = () => store.commit(SET_FOUND_PRODUCTS, [])
    const setSearchQuery = (query) => {
      searchQuery.value = query
      updateRouter()
      clearFoundProducts()
    }

    return {
      onSearch,
      isLoading,
      showSearch,
      setSearchQuery,
      foundProducts,
      cart,
      cartItemsCount,
      cartTotalPrice,
      paginate,
      filters,
      searchQuery,
      updateRouter,
      // debouncedUpdateRouter,
      onScrollPaginate,
      catalogContainer,
      showPlaceholders,
      products,
      pagination
    }
  }
}
</script>

<template>
  <div class="catalog__inner">
    <div class="table catalog__table">
      <div class="table__head">
        <div class="table__th table__th--first">
          Наименование / Штрих-код
        </div>
        <div class="table__th table__th--second">
          Цена
        </div>
        <div class="table__th table__th--fourth">
          Количество
        </div>
      </div>
      <product-list-item-placeholder v-for="index in placeholderCount" :key="index" />
    </div>
  </div>
</template>

<script>
import ProductListItemPlaceholder from './ProductListItemPlaceholder.vue'
export default {
  components: {
    ProductListItemPlaceholder
  },
  props: {
    placeholderCount: {
      type: Number,
      default: 10
    }
  }
}
</script>

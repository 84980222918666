<template>
  <div class="table__list">
    <div class="table__col table__col--first">
      <div class="table__col-first">
        <div v-if="product.image" class="table__image">
          <img :src="product.image" :alt="product.name">
        </div>
        <div class="table__content">
          <div class="table__name">
            {{ product.name }}
            <span v-if="product.is_bestseller" class="table__category" data-r-prompt="Самый покупаемый">
              <svg class="s-icon">
                <use xlink:href="/img/svg/filters.svg#hit" />
              </svg>
            </span>
            <span v-if="product.has_promotion" class="table__category" data-r-prompt="Спецпредложение">
              <svg class="s-icon">
                <use xlink:href="/img/svg/filters.svg#sale" />
              </svg>
            </span>
          </div>
          <div class="table__signature">
            {{ barcodes }}
          </div>
        </div>
      </div>
    </div>
    <div class="table__col table__col--second active">
      <div class="table__col-second">
        <div class="table__name">
          {{ price }}
        </div>
        <div class="table__signature">
          {{ vat }}{{ format === ORDER_FORMAT_PACKAGE ? ` • уп. ${product.package_quantity} шт.` : '' }}
        </div>
      </div>
    </div>
    <div class="table__col table__col--fourth">
      <div class="table__col-fourth">
        <template v-if="productInCart">
          <span v-if="cartError" class="table__quantity-error" :data-prompt="cartError">
            <svg class="s-icon">
              <use xlink:href="/img/svg/sprite1.svg#warning" />
            </svg>
          </span>
          <div class="table__quantity" :class="{ 'table__quantity--error': cartError }">
            <input
              v-model="quantity"
              class="table__quantity-field"
              type="number"
              min="0"
              placeholder="0"
              @change="updateCartItem"
            >
          </div>
          <div class="table__select">
            <vue-multiselect
              v-if="product.price && product.package_price"
              :id="`format-${product.id}`"
              v-model="format"
              :options="[ORDER_FORMAT_PIECE, ORDER_FORMAT_PACKAGE]"
              :multiple="false"
              :searchable="false"
              :clear-on-select="false"
              :show-labels="false"
              :allow-empty="false"
              @update:model-value="updateCartItem"
            >
              <template #singleLabel="{ option }">
                <span class="table__box-text">
                  {{ formats[option] }}
                </span>
              </template>
              <template #option="{ option }">
                <span class="table__box-text">
                  {{ formats[option] }}
                </span>
              </template>
            </vue-multiselect>
            <div v-else class="select__content">
              <span class="table__box-text">
                {{ formats[format] }}
              </span>
            </div>
          </div>
          <button class="table__basket-remove" data-r-prompt="Удалить из корзины" @click="removeCartItem">
            <svg class="s-icon s-icon--largest"><use xlink:href="/img/svg/sprite1.svg#cancel" /></svg>
          </button>
        </template>
        <button
          v-else
          class="btn btn--secondary btn--large btn--icon table__basket-add added"
          @click="addToCart"
        >
          <svg class="s-icon">
            <use xlink:href="/img/svg/sprite1.svg#cart_add" />
          </svg>
          <span class="btn__text">В корзину</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useProductState from '@/composables/useProductState'
import useProductFields from '@/composables/useProductFields'
import { ORDER_FORMAT_PIECE, ORDER_FORMAT_PACKAGE, formats } from '@/common/orderFormats'
import VueMultiselect from 'vue-multiselect'

export default {
  components: {
    VueMultiselect
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { format, quantity, addToCart, price, cartError, productInCart, updateCartItem, removeCartItem } = useProductState(props.product)
    const { barcodes, vat } = useProductFields(props.product, false)
    return {
      productInCart,
      cartError,
      price,
      ORDER_FORMAT_PIECE,
      ORDER_FORMAT_PACKAGE,
      formats,
      format,
      quantity,
      removeCartItem,
      updateCartItem,
      addToCart,
      barcodes,
      vat
    }
  }
}
</script>

<template>
  <div class="filters">
    <div class="container">
      <div class="filters__inner">
        <div class="filters__box">
          <ul class="filters__list">
            <li v-if="filters[NON_FOOD]" class="filters__item">
              <label class="k-checkbox" style="--bg: #E7EDFD; --color: #2B5CD6;">
                <input
                  v-model="innerCategories"
                  class="k-checkbox__input"
                  type="checkbox"
                  :value="NON_FOOD"
                >
                <span class="k-checkbox__box btn">
                  <span class="k-checkbox__icon">
                    <svg class="s-icon">
                      <use xlink:href="/img/svg/filters.svg#non-food" />
                    </svg>
                  </span>
                  <span>Non-food</span>
                </span>
              </label>
            </li>
            <li v-if="filters[FOOD]" class="filters__item">
              <label class="k-checkbox" style="--bg: #E7EDFD; --color: #2B5CD6;">
                <input
                  v-model="innerCategories"
                  class="k-checkbox__input"
                  type="checkbox"
                  :value="FOOD"
                >
                <span class="k-checkbox__box btn">
                  <span class="k-checkbox__icon">
                    <svg class="s-icon">
                      <use xlink:href="/img/svg/filters.svg#food" />
                    </svg>
                  </span>
                  <span>Food</span>
                </span>
              </label>
            </li>
            <li v-if="filters[CAFE]" class="filters__item">
              <label class="k-checkbox" style="--bg: #E7EDFD; --color: #2B5CD6;">
                <input
                  v-model="innerCategories"
                  class="k-checkbox__input"
                  type="checkbox"
                  :value="CAFE"
                >
                <span class="k-checkbox__box btn">
                  <span class="k-checkbox__icon">
                    <svg class="s-icon">
                      <use xlink:href="/img/svg/filters.svg#cafe" />
                    </svg>
                  </span>
                  <span>Зона кафе</span>
                </span>
              </label>
            </li>
          </ul>
          <ul class="filters__list">
            <li v-if="filters.is_bestseller" class="filters__item">
              <label class="k-checkbox" style="--bg: #F6E2F6; --color: #AA33AD;">
                <input
                  v-model="innerIsBestseller"
                  class="k-checkbox__input"
                  type="checkbox"
                  value="is_bestseller"
                >
                <span class="k-checkbox__box btn">
                  <span class="k-checkbox__icon">
                    <svg class="s-icon">
                      <use xlink:href="/img/svg/filters.svg#hit" />
                    </svg>
                  </span>
                  <span>Хиты продаж</span>
                </span>
              </label>
            </li>
            <li v-if="filters.has_promotion" class="filters__item">
              <label
                class="k-checkbox"
                style="--bg: #FFE1E1; --color: #D72900;"
              >
                <input
                  v-model="innerHasPromotion"
                  class="k-checkbox__input"
                  type="checkbox"
                  value="has_promotion"
                >
                <span class="k-checkbox__box btn">
                  <span class="k-checkbox__icon">
                    <svg class="s-icon">
                      <use xlink:href="/img/svg/filters.svg#sale" />
                    </svg>
                  </span>
                  <span>Акции</span>
                </span>
              </label>
            </li>
          </ul>
        </div>
        <div class="filters__btn" v-if="props.hasItems">
          <button class="btn btn--secondary btn--icon">
            <span class="btn__icon">
              <svg class="s-icon">
                <use xlink:href="/img/svg/sprite1.svg#upload" />
              </svg>
            </span>
            <span class="btn__text">
              Импорт из файла
            </span>
          </button>
        </div>
        <div class="popup-upload">
          <p class="popup-upload__text">
            Для добавления товаров в корзину списком Excel
            <a href="/cart-template.xlsx" target="blank">скачайте шаблон</a>, заполните количество и загрузить его
          </p>
          <div class="popup-upload__row">
            <label class="k-file popup-upload__btn">
              <input class="k-file__input" type="file" @change="importToCart">
              <span class="k-file__box btn btn--primary">
                <span class="k-file__icon">
                  <svg class="s-icon">
                    <use xlink:href="/img/svg/sprite1.svg#upload-white" />
                  </svg>
                </span>
                <span> Загрузить файл</span>
              </span>
            </label>
            <span class="popup-upload__extensions">.xls, .xlsx</span>
          </div>
          <div class="message-info popup-upload__info">
            <svg class="s-icon message-info__icon">
              <use xlink:href="/img/svg/sprite1.svg#info" />
            </svg>
            <p class="message-info__text">
              При заполнении шаблона не добавляйте новых столбцов в документ, а также не изменяйте заголовок у шапки
              таблицы.
            </p>
          </div>
        </div>
      </div>
    </div>
    <cart-modal v-model="showModal" modal-title="Импорт товаров" :modal-callback="modalCallback" />
  </div>
</template>

<script>
import CartModal from '../CartModal.vue'
import useCartImport from '@/composables/useCartImport'
import { FOOD, NON_FOOD, CAFE } from '@/common/productCategories'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useApi } from '@/api'
import { useToast } from 'vue-toastification'

export default {
  components: { CartModal },
  props: {
    categories: {
      type: Array,
      required: true
    },
    isBestseller: {
      type: Boolean
    },
    hasPromotion: {
      type: Boolean
    },
    hasItems: {
      type: Boolean
    }
  },
  emits: ['update:categories', 'update:isBestseller', 'update:hasPromotion'],
  setup(props, { emit }) {
    const innerCategories = computed({
      get() {
        return props.categories
      },
      set(categories) {
        emit('update:categories', categories)
      }
    })
    const innerIsBestseller = computed({
      get() {
        return props.isBestseller
      },
      set(isBestseller) {
        emit('update:isBestseller', isBestseller)
      }
    })
    const innerHasPromotion = computed({
      get() {
        return props.hasPromotion
      },
      set(hasPromotion) {
        emit('update:hasPromotion', hasPromotion)
      }
    })
    
    const filters = ref({})

    const api = useApi()
    const toast = useToast()
    api.products.fetchFilters().then(({ data }) => {
      filters.value = data
    }).catch(() => {
      toast.error('Произошла ошибка')
    })

    const router = useRouter()
    const { importToCart, modalCallback, showModal } = useCartImport(() => router.push('/cart'))
    return {
      modalCallback,
      showModal,
      filters,
      importToCart,
      innerCategories,
      innerIsBestseller,
      innerHasPromotion,
      FOOD,
      NON_FOOD,
      CAFE,
      props
    }
  }
}
</script>
